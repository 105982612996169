$creditcardprocessor-why-us-brief: #747474;
$creditcardprocessor-default-link-color: #4558a7;
$creditcardprocessor-default-button-bgcolor: #4558a7;
$creditcardprocessor-default-button-text-color: #ffffff;
$creditcardprocessor-default-checkbox-selected-bgcolor: #e9f1ff;

:export {
    primaryColor: $creditcardprocessor-default-button-bgcolor;
    whyUsBriefColor: $creditcardprocessor-why-us-brief;
    linkColorDefault: $creditcardprocessor-default-link-color;
    buttonBgColorDefault: $creditcardprocessor-default-button-bgcolor;
}
